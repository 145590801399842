import $ from 'jquery';

import {getFullSuburbList, getSuburbListAutoComplete} from "../../components/calculators/calculator-suburb-list";
import {getPostCodeMap, isBrokerSearchInputValid}     from "./location-valuers-helpers";
import {isElementInViewport}                          from "../../components/calculators/calculator-utils";

export default function (window) {
    if ($('.broker-search').length >= 1) {
        initBrokerLazyLoad();
        $('.broker-search .preloader').show();

        var $postcodeField = $("#broker_search_suburb");

        // do request
        var searchTerm = $postcodeField.val().trim();

        if (searchTerm == '') {
            $('.broker-search .preloader').hide();
            return false;
        }

        var API_URL = window.location.origin + '/api/brokersearchservice/search/postcode/';
        var postcode = isBrokerSearchInputValid(searchTerm);
        if (postcode) {

            $("#broker_search_submit").addClass('loading');
            $.get(API_URL + postcode, function (data) {
                //console.log(data);
                var $resultsContainer = $("#broker_search_results");

                $resultsContainer.html('');
                if (data.totalCount == 0) {
                    /* show no results found*/
                    if (data.errorMessage !== null) {

                        $resultsContainer.html('<h3 class="no-results">No results found</h3>');
                    } else {
                        console.log(data.errorMessage);
                        $resultsContainer.html('<h3 class="no-results">No results found</h3>');
                        //$resultsContainer.html('<h3>'+data.errorMessage+'</h3>');
                    }
                } else {
                    // reset results container
                    $resultsContainer.html('');

                    $.each(data, function (k, response) {
                        //console.log(k, response);

                        // If there's excluded suburbs, mention them here
                        var template = responseTemplate(response, k);
                        $resultsContainer.append(template);

                    });

                    $resultsContainer.prepend(`<div class="column tiny-12 count">Your broker search for ${searchTerm} has returned <strong>${data.length} result${data.length === 1 ? '' : 's'}.</strong></div>`)
                    // Handle result
                    // Loop through each LVRLocationResponse
                    //
                }
            });
            // Fetch search results


        } else {

        }
        $('.broker-search .preloader').hide();

        $("#broker_search_submit").removeClass('loading');
    }
}


function initBrokerLazyLoad() {
    $(document).on('scroll', function (e) {
        // Get first hidden
        var hidden = $(".staff.hidden");
        var first = $(".staff.hidden").first();
        if (first.length >= 1) {
            if (isElementInViewport(first)) {
                $(".staff.hidden").each(function (k, elem) {
                    if (k == 4) {
                        return false;
                    } else {
                        $(elem).fadeIn().removeClass('hidden');
                    }
                })
            }
        }

    })
}

function responseTemplate(data, key) {
    var additionalClass = "";
    if (key >= 8) {
        additionalClass = "hidden"
    }
    data.image = data.image.replace("~", "");
    var template = `
    <div class="column large-4 medium-6 tiny-12 staff ${additionalClass}">
        <!--<img class="portrait" src="${data.image}">-->
        <h5 class="name">${data.firstName} ${data.lastName}</h5>
        <h6 class="company">${data.companyName}</h6>`

    if (data.city != "") {
        template += `<p class="contact suburb"><img class="icon" src="/Content/img/icons/icon-location.png">${data.city}</p>`
    }
    if (data.phone != "") {
        template += `<p class="contact phone"><img class="icon" src="/Content/img/icons/icon-phone.png">P<span><a href="tel:${data.phone}">${data.phone}</a></span></p>`
    }
    if (data.email != "") {
        template += `<p class="contact email"><img class="icon" src="/Content/img/icons/icon-email.png">E<span><a href="mailto:${data.email}">${data.email}</a></span></p>`;
    }
    template += `</div>`

    return template;

}
  
