import $ from "jquery";

export default (window) => {
    let $customerRegistration = $("#registration_submit")
    if ($customerRegistration.length == 0) {
        return;
    }
    let $formWrapper = $("div[id^='form-']");
    $formWrapper.attr('style', 'visibility:hidden;height:0;')
    let $startRegistration = $("#start_registration");
    let checkboxes = ["over18", "aus_resident", "sa_house", "have_income", "bankrupt"];
    $startRegistration.on('click', function () {
        // Determine if all checkboxes are checked
        let allClicked = true;
        $.each(checkboxes, function (k, checkbox) {
            if (!$('input[name=' + checkbox + ']').is(':checked')) {
                allClicked = false;
            }
        })
        if (!allClicked) {
            let listOfNotChecked = "";
            let checkboxes = ["over18", "aus_resident", "sa_house", "have_income", "bankrupt"];
            $.each(checkboxes, function (k, checkbox) {
                if (!$('input[name=' + checkbox + ']').is(':checked')) {
                    listOfNotChecked += checkbox + ",";
                }
            })
            console.log("GA SEND", {
                'event': 'event',
                'eventCategory': 'Eligibility',
                'eventAction': 'Exception',
                'eventLabel': listOfNotChecked
            });
            window.dataLayer.push({
                'event': 'event',
                'eventCategory': 'Eligibility',
                'eventAction': 'Exception',
                'eventLabel': listOfNotChecked
            });

            $("#registration_submit").slideUp(250, function () {
                $("#register_failure").slideDown(250);
            });
        } else {

            $('.registration-text-wrapper').slideUp();
            $('#customer_prequal').slideUp();
            let $form = $("form[id^='form-']");

            // Injected next to the form
            // Remove existing element that's in the middle of where we want shit
            $('.registration-text-wrapper').parent().remove();
            //let formHtml = $formWrapper.html();
            let registrationText = `
                <div class="column tiny-12 large-4 register-side-text" >
                    <h2>Register for customer self-service portal</h2> 
                    <p>We will begin your online application by creating a secure online account so you can save your changes and return to your application whenever is convenient for you. </p>
                    <p>When you add your details on this form an email will be sent to you to create your account. Follow the prompts and then return to this form to log in.</p>
                </div>
                <div class="note"><strong>Please note:</strong> The person registering for this portal must be the primary loan applicant.</div>
            `
            $formWrapper.parent().prepend(registrationText);
            $formWrapper.parent().addClass('row').attr('style', 'margin:30px auto;position:relative;');
            $formWrapper.attr('style', 'visibility:visible;height:auto;').slideDown().attr('style', 'visibility:visible;height:auto;');
            const menuHeight = $('header').filter(':visible').outerHeight();
            $('html, body').animate({
                scrollTop: ($('.hero').offset().top + $('.hero').height()) - (menuHeight)
            }, 500);
            window.dataLayer.push({
                'event': 'VirtualPageview',
                "virtualPageURL": '/home-loans/apply-now/register',
                "virtualPageTitle": 'Home Loans – Apply Now - Register'
            });
        }
    });
    window.registerFirstSubmit = false;
    // Strip white space in emails
    $('body').on('blur', 'form input[name*="Email.Value"]', function () {
            const email = $('form input[name*="Email.Value"]').val();
            $('form input[name*="Email.Value"]').val(email.trim())
        }
    )
    $('body').on('click', 'input[value="Create account"]', function (e) {
        console.log('sending');


        if ($(this).hasClass('disable')) {
            return false;
        }

        $(this).addClass('disable');

        // set up hidden field value
        //const firstName = $('div[id*="CustomerRegistration"] input[name*="FirstName"]').val();
        //const lastName = $('div[id*="CustomerRegistration"] input[name*="LastName"]').val();
        //const mobile = $('div[id*="CustomerRegistration"] input[name*="Mobile"]').val();
        const firstName = $('form input[name*="FirstName.Value"]').val();
        const lastName = $('form input[name*="LastName.Value"]').val();
        const mobile = $('form input[name*="Phone.Value"]').val();

        $('form input[name*="AllFields.Value"]').val(JSON.stringify({
            firstName,
            lastName,
            mobile
        }));
        console.log('Registration validation field set to')
        console.log({
            firstName,
            lastName,
            mobile
        })

        console.log($('form input[name*="AllFields.Value"]').val());

        if (window.registerFirstSubmit == false) {
            $('.explanation-text').hide();
            window.registerFirstSubmit = true;
        }

        let $form = $("form[id^='form-']");
        let $checkbox = $form.find('input[type="checkbox"');
        if ($checkbox.is(':checked')) {
            console.log("GA SEND:", {
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Allow contact',
                'eventLabel': 'checked'
            })
            window.dataLayer.push({
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Allow contact',
                'eventLabel': 'checked'
            });

        } else {
            console.log("GA SEND:", {
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Allow contact',
                'eventLabel': 'unchecked'
            });
            window.dataLayer.push({
                'event': 'event',
                'eventCategory': 'Loan Application Form',
                'eventAction': 'Allow contact',
                'eventLabel': 'unchecked'
            });
        }
    });


    // Allow whole box to toggle checkbox
    $('.registration-cards').on('click', '.card', function (e) {
        e.preventDefault();
        //e.stopPropagation();
        var $card = $(this);
        //$card.find('label').trigger('click');
        let $input = $card.find('input');
        if ($input.is(':checked')) {
            $input.removeAttr('checked').prop('checked', false);
        } else {
            $input.attr('checked', 'checked').prop('checked', true);
        }
    });

    $('.registration-cards').on('click', 'input,label', function (e) {
        //e.preventDefault()
        e.stopPropagation();

    });

    $('.registration-cards').on('click', 'input', function (e) {
        if ($("#register_failure").is(':visible')) {
            $("#register_failure").slideUp();
            $("#registration_submit").slideDown();
        }
    })
    $('.registration-cards').on('click', '.card', function (e) {
        if ($("#register_failure").is(':visible')) {
            $("#register_failure").slideUp();
            $("#registration_submit").slideDown();
        }
    })
}