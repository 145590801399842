import $ from 'jquery';
import { isMobileOrTablet } from "../utils";

var init = function (window) {

  $('.desktop-menu .menu-item a').click(function (e) {
    if ($(e.currentTarget).attr('href') !== '#')
      return true;

    e.preventDefault();
    return false;
  });

  // mobile nav
  $('.mobile-navigation .menu-list > .menu-item-has-children > a').click(function (e) {
    e.preventDefault();

    var $menuItems = $('.mobile-navigation .menu-list > .menu-item-has-children');
    var $menuItem = $(this).parent();
    var $navigation = $menuItem.parents('.navigation');

    $menuItems.not($menuItem).removeClass('open');

    $menuItem.toggleClass('open');

    if (!$menuItems.filter('.open').length)
      $navigation.removeClass('open');
    else
      $navigation.addClass('open');

    updateMobileNavigation();
  });
  $('.broker-page [data-search]').on('click',function(e) { 
      $('.menu-item.search:visible:first').toggleClass('open');
  })
  $('.board-page [data-search]').on('click',function(e) { 
      $('.menu-item.search:visible:first').toggleClass('open');
  })
  // Uncomment if the menu doesn't need to overflow internally.
  // Prevents document from scrolling underneath
  // $('.mobile-navigation').on('touchmove mousewheel', '.navigation.open', function(e){
  //   e.stopPropagation();
  //   e.preventDefault();
  //   return false;
  // });

  // search code for the main site. 

  function inlineSearchFocus() {
    var timer;
  
    $('body').on('mouseenter', '.menu-item.search-inline', function () {
      clearTimeout(timer);
      open();
    });
  
    $('body').on('mouseleave', '.menu-item.search-inline', function () {
      timer = setTimeout(close, 500); // Delay of 500ms
    });
  
    $('body').on('click', '.menu-item .search-input', function () {
      // focus();
    });
  
    $('body').on('click', ':not(.search-input), .menu-item:not(.search-inline)', function () {
      if ($('.search-input').css("opacity") == "1") {
        close();
      }
    });
  
    function open() {
      $('.before-search').fadeTo(200, 0);
      $('.search-input').css('display', 'block');
      $('.search-input').animate({
        width: 281,
        opacity: 1
      }, 250);
      $('.before-search').css('display', 'none');
      $('.before-search').animate({
        width: 281
      }, 200);
    }
  
    function close() {
      if (!$('.search-input').is(":focus")) {
        $('.search-input').animate({
          width: 50
        }, 250);
        $('.search-input').fadeTo(200, 0);
        $('.before-search').animate({
          width: 50,
          opacity: 1
        }, 200);
        $('.before-search').css('display', 'block');
        $('.search-input').css('display', 'none');
      }
    }
  }
  inlineSearchFocus();

  // Other search code that is used throughout the site.

  $('.mobile-navigation .sub-menu').on('touchmove mousewheel', function (e) {
    var $el = $(this);

    if ($el.scrollTop() <= 0)
      return $el.scrollTop(1);

    if ($el[0].scrollHeight - $el.scrollTop() <= $el.outerHeight())
      return $el.scrollTop($el.scrollTop() - 1);

    e.stopPropagation();
  });

  // Mobile SubMenus
  $('.mobile-navigation .menu-item-has-children > a').click(function (e) {
    var $el = $(this);
    var $subMenuWrapParent = $el.closest('.fullscreen-wrap');
    var $subMenuWrap = $el.siblings('.fullscreen-wrap');

    e.preventDefault();

    $subMenuWrapParent.addClass('closed');
    $subMenuWrap.addClass('open');
  });

  //Mobile Menu Back
  $('.mobile-navigation .menu-back').click(function (e) {
    var $el = $(this);

    e.preventDefault();

    $el.closest('.closed').removeClass('closed');

    setTimeout(function () {
      $el.closest('.open').removeClass('open');
    }, 300);
  });

  function searchFocus() {
    $('body').on({
      // click to TOGGLE
      click: function () {
        toggle();
        focus();
      },
      // hover to OPEN and FOCUS
      mouseenter: function () {
        open();
        focus();
      },
      // leave hover to UNFOCUS
      mouseleave: function () {
        unfocus();
      }
    }, '.menu-item.search');

    $('body').on('click', '[data-search]', function (e) {
      if (Foundation.MediaQuery.is('large up')) {
        e.stopPropagation();
        $('.before-search').fadeTo(200, 0);
        $('.search-input').css('display', 'block');
        $('.search-input').animate({
          width: 281,
          opacity: 1
        }, 250);
        $('.before-search').css('display', 'none');
        $('.before-search').animate({
          width: 281
        }, 200);
        $('.search-input').focus();
        //$('.menu-button').click();

        //if( $('html').hasClass('menu-open') ) {
        //setTimeout(function () {
        //$('[name="searchtext"]', menu).focus();
        //}, 300);
        //}
      }
    });

    $('body').on({
      // click a DIFFERENT MENU ITEM to close
      click: function () {
        close();
        unfocus();
      },
      // hover a DIFFERENT MENU ITEM to close
      mouseenter: function () {
        close();
        unfocus();
      },
    }, '.menu-item:not(.search)');

    // click anywhere that IS NOT THE SEARCH to close
    $('body').on('click', function () {
      if (typeof event != "undefined" && typeof event.target != "undefined") {
        const $target = $(event.target);
        const $searchMenu = $('.menu-item.search.open');

        if (!$target.closest('.menu-item.search.open').length && $searchMenu.is(":visible")) {
          close();
          unfocus();
        }
      }
    });

    function toggle() {
      $('.menu-item.search:visible:first').toggleClass('open');
    }

    function open() {
      $('.menu-item.search:visible:first').addClass('open');
    }

    function close() {
      $('.menu-item.search:visible:first').removeClass('open');

    }

    function focus() {
      $('body').find('.search-panel input:visible:first').focus();
    }

    function unfocus() {
      if (!isMobileOrTablet()) {
        document.getElementById("blur-hack").focus();
      }
    }
  }

  // Fixed Menu Updater
  function updateStickyNavigation() {
    var $mobileMenu = $('.mobile-navigation');
    var $desktopMenu = $('.desktop-navigation');
    var $menu = $('.mobile-navigation, .desktop-navigation').filter(':visible');

    if ($(window).scrollTop() <= 0)
      return $menu.removeClass('menu-sticky');

    if ($(window).scrollTop() >= 0 && !$menu.hasClass('menu-sticky')) {
      $menu.addClass('menu-sticky');
      $menu.addClass('menu-hide');
      window.document.body.offsetWidth;
      $menu.removeClass('menu-hide');
    }
  }

  //update mobile heights for menu
  function updateMobileNavigation() {
    var $menu = $('.mobile-navigation');
    var offset = $menu.find('.navigation').height() + $('#wpadminbar').height();
    var height = window.innerHeight;

    $menu.find('.fullscreen-wrap')
      .height(height - offset);
  }

  // var pageScroll = function(){
  //   updateStickyNavigation();
  // };

  var pageResize = window.Foundation.util.throttle(function () {
    updateMobileNavigation();
  }, 60);

  // $(window).scroll(pageScroll);
  $(window).resize(pageResize);

  // pageScroll();
  pageResize();
  searchFocus();
}

export default function (window) {
  init(window);
}