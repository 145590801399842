import $ from "jquery";
import authoriseThirdParty from './authorise-third-party';
import homeEquityLoan from './home-equity-loan';
import requestFinancialAssistance from './request-financial-assistance';
import updateContactDetails from "./update-contact-details";
import updatePaymentDetails from "./update-payment-details";
import pausePayments from "./pause-payments";
import redraw from "./redraw";
import statementsAndDocuments from "./statements-and-documents";
import switchInterestRate from "./switch-interest-rate";

export default (window) => {
    authoriseThirdParty();
    homeEquityLoan();
    requestFinancialAssistance();
    updateContactDetails();
    updatePaymentDetails();
    pausePayments();
    redraw();
    statementsAndDocuments();
    switchInterestRate();


    $("input[data-type='currency']").on({
        keyup: function (e) {
            if (e.key === "Enter") {
                e.preventDefault();
                formatCurrency($(this));
            }
        },
        blur: function () {
            formatCurrency($(this));
        }
    });


    $(document).ready(function () {
        //PrepareCustomMenus()
        updateNotificationCount();

        $('.view-notification-button').on("click", function () {
            $('.modal').css('display', 'none');

            const API_URL = '/api/calmsdashboard/ViewedNotification';
            var notificationID = $(this).attr("notificationID");
            var index = $(this).attr("notificationIndex");

            document.getElementById(index).style.display = 'block';
            document.body.style.overflow = 'hidden'; 

            var payload = { 'itemID': notificationID };

            var newID = "#new" + notificationID;
            console.log("newID = " + newID);
            $(newID).css('display', 'none');

            $.ajax({
                url: API_URL,
                data: payload
            }).done(setTimeout(updateNotificationCount, 1000));
        });
    });

    if (document.location.href.includes('transacting-customer')) { setTimeout(checkLogin, 60000); }
}

function checkLogin() {

    $.ajax({
        url: "/api/calmsdashboard/expiredtoken",
        success: function (result) {
            if (result == "True" && document.location.href.includes('transacting-customer')) {
                document.location = "/api/Account/Login";
                return;
            }
        }
    });

    setTimeout(checkLogin, 60000);
}

function formatNumber(n) {
    // format number 1000000 to 1,234,567
    n = n.toString();
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatCurrency(input) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();
    if (input_val === "") { return; }
    var original_len = input_val.length;
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(".") >= 0) {

        // get position of first decimal this prevents multiple decimals from being entered
        var decimal_pos = input_val.indexOf(".");
        var left_side = input_val.substring(0, decimal_pos);
        var right_side = input_val.substring(decimal_pos);

        left_side = left_side.replace(/\D/g, '');
        var left_side_int = parseInt(left_side);

        if (isNaN(left_side_int)) { left_side_int = 0; }

        left_side = formatNumber(left_side_int);
        right_side = formatNumber(right_side);                
        right_side += "00";

        // Limit decimal to only 2 digits
        right_side = right_side.substring(0, 2);

        // join number by .
        input_val = "$" + left_side + "." + right_side;

    } else {

        input_val = input_val.replace(/\D/g, '');
        var parsed_input = parseInt(input_val);

        if (isNaN(parsed_input)) { parsed_input = 0; }

        input_val = formatNumber(parsed_input);
        input_val = "$" + input_val;
        input_val += ".00";
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
}

/*function PrepareCustomMenus(){
    var x, i, j, l, ll, selElmnt, a, b, c;
    x = document.getElementsByClassName("custom-select");
    l = x.length;
    for (i = 0; i < l; i++) {
        selElmnt = x[i].getElementsByTagName("select")[0];
        ll = selElmnt.length;
        a = document.createElement("DIV");
        a.setAttribute("class", "select-selected");
        a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
        if (selElmnt.options[selElmnt.selectedIndex].classList.contains("placeholder"))
        { a.style.color = "#848484";}

        x[i].appendChild(a);
        b = document.createElement("DIV");
        b.setAttribute("class", "select-items select-hide");
        for (j = 1; j < ll; j++) {
            c = document.createElement("DIV");
            c.innerHTML = selElmnt.options[j].innerHTML;
            c.addEventListener("click", function (e) {
                var y, i, k, s, h, sl, yl;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                sl = s.length;
                h = this.parentNode.previousSibling;
                for (i = 0; i < sl; i++) {
                    if (s.options[i].innerHTML == this.innerHTML) {
                        s.selectedIndex = i;
                        h.innerHTML = this.innerHTML;
                        y = this.parentNode.getElementsByClassName("same-as-selected");
                        yl = y.length;
                        for (k = 0; k < yl; k++) {
                            y[k].removeAttribute("class");
                        }
                        this.setAttribute("class", "same-as-selected");
                        break;
                    }
                }
                h.style.color = "#000";
                h.click();
            });
            b.appendChild(c);
        }
        x[i].appendChild(b);
        a.addEventListener("click", function (e) {
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
        });
    }

    document.addEventListener("click", closeAllSelect);
    console.log("Prepared custom option menus");
}

function closeAllSelect(elmnt) {
    var x, y, i, xl, yl, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}*/

function updateNotificationCount() {
    const notificationCount_API_URL = '/api/calmsdashboard/GetNewNotificationCount';
    $.ajax({ url: notificationCount_API_URL }).done(function (result) {
        if (result == 0) {
            $('.notification-count').html("");
            $('.notification-count-alert').css("display", "none");
        }
        else {
            $('.notification-count').html("(" + result + ")");
            $('.notification-count-alert').css("display", "block");
            $('.notification-count-alert').html(result);
        }

        console.log("Update notification count");
    });
}

