import $ from 'jquery';

import {getFullSuburbList, getSuburbListAutoComplete} from "../../components/calculators/calculator-suburb-list";
import {getPostCodeMap, isSearchInputValid}           from "./location-valuers-helpers";

export default function (window) {
    if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
            'use strict';
            if (typeof start !== 'number') {
                start = 0;
            }

            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }

    var $postcodeField = $("#location_search_suburb");
    var $resultsContainer = $("#location_search_results");
    // do request
    var searchTerm = $postcodeField.val();
    //var API_URL = window.location.origin + '/api/suburb/search/suburborpostcode/';
    var API_URL = window.location.origin + '/api/locationsearchservice/search/postcode/';
    if (isSearchInputValid(searchTerm)) {
        $("#location_search_submit").addClass('loading');
        $.get(API_URL + searchTerm, function (data) {
            //console.log(data);
            var responseData = data.locations;

            var locationsCount = 0;
            $resultsContainer.html('');
            if (data.totalCount == 0) {
                /* show no results found*/
                if (data.errorMessage !== null && data.errorMessage != "") {
                    $resultsContainer.prepend(`<p class="count tiny-12">${data.errorMessage}</p>`)
                } else {
                    //console.log(data.errorMessage);
                    $resultsContainer.html('');
                    //$resultsContainer.html('<h3>'+data.errorMessage+'</h3>');
                    $resultsContainer.prepend(`<p class="count tiny-12">Your location search for ${searchTerm} has returned <strong>0</strong> results.</p>`)
                }
            } else {
                // reset results container
                $resultsContainer.html('');
                var excludedResults = [];

                $.each(responseData, function (k, response) {
                    //console.log(k, response);
                    if (response.categoryID == 5 && response.category == "Uncategorised" || response.category == "Excluded") {
                        excludedResults.push(response);
                        return;
                    } else {
                        locationsCount++;
                        // If there's excluded suburbs, mention them here
                        var template = responseTemplate(response);
                        template = template.replace('undefined', '');
                        $resultsContainer.append(template);
                    }

                });


                // Handle excluded results
                var excludedSuburbOrSuburbs = (excludedResults.length == 1) ? "Suburb" : "Suburbs"
                if (excludedResults.length >= 1) {
                    $resultsContainer.append(`<p><strong>Excluded ${excludedSuburbOrSuburbs}:</strong></p>`);
                    $.each(excludedResults, function (k, data) {
                        //console.log('excluded results', data);
                        var excludedTemplate = `
                        <div class="location-search-response excluded column tiny-12" style="opacity:0.5">
                            <h3 class="title">${data.suburb}, SA (${data.postcode})</h3>
                        <hr />
                        <div class="info">${data.categoryDescription}</div>
                        </div>`
                        $resultsContainer.append(excludedTemplate);
                    })
                }
                var locationSearchString = `<p class="count tiny-12">`
                var suburbOrSuburbs = ($('.location-search-response:not(.excluded)').length == 1) ? "Suburb" : "Suburbs";

                if ($('.location-search-response:not(.excluded)').length >= 1) {
                    locationSearchString += `Your location search for ${searchTerm} has returned <strong>${$('.location-search-response').length} ${suburbOrSuburbs.toLowerCase()}</strong> we lend to`

                }
                if (excludedResults.length >= 1 && locationsCount >=1) {
                    locationSearchString += ` and ${$('.location-search-response.excluded').length} excluded ${excludedSuburbOrSuburbs.toLowerCase()}`
                } else if(excludedResults.length >=1 && $('.location-search-response:not(.excluded)').length == 0) {

                    locationSearchString += `Your location search for ${searchTerm} has returned ${$('.location-search-response.excluded').length} excluded ${excludedSuburbOrSuburbs.toLowerCase()}.`
                }
                var tmp = locationSearchString.toString();
                if(tmp[tmp.length-1] != ".") {

                    locationSearchString += `.`;
                }
                if ($('.location-search-response:not(.excluded)').length >= 1) {
                    locationSearchString += `<br/><br/><strong>Suburbs we lend to:</strong>`
                }
                locationSearchString += `</p><br/>`

                $resultsContainer.prepend(locationSearchString);


                $resultsContainer.show();

            }

            $("#location_search_submit").removeClass('loading');
        });
        // Fetch search results


    } else {

    }

}


function responseTemplate(data) {
    //console.log(data);


    if ($('[data-locality-id="' + data.localityID + '"]').length >= 1) {
        console.log('Skipping duplicate category', data);
        return;
    }
    var template = `
        <div class="location-search-response column tiny-12" data-locality-id="${data.localityID}" data-category-id="${data.categoryID}">
            <h3 class="title">${data.suburb}, SA (${data.postcode})</h3>
            <h5>Category: ${data.category}</h5>
          <hr />
          `

    var seniorsEquityLink = window.location.origin + "/broker/calculators?seniors-equity";
    var description = (data.categoryDescription !== undefined) ? data.categoryDescription : "";
    if (description.toString().includes("Seniors Equity Calculator")) {
        description = description.toString().replace("Seniors Equity Calculator", "<a href='" + seniorsEquityLink + "' target='_blank'>Seniors Equity Calculator</a>");
    }
    if(description.includes('undefined' ) || typeof description === undefined ) {
        description.replace('undefined','');
    }
    template += `
        <div class="info">
            <p>
                The table below displays the maximum LVR lending thresholds for available HomeStart loans based on the purpose of the loan application. ${description} 
            </p>
            <p>
                Shared Equity Option is available for this location, please refer to calculator for maximum LVR.
            </p>
        </div>

       <table>
        <thead>
        <tr>
            <th>Product / Purpose </th>
            <th colspan="5">Maximum LVR</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>
               &nbsp;
            </td>
            <td><strong>House Purchase</strong></td>
            <td><strong>Land Purchase</strong></td>
            <td><strong>Land Purchase & Build</strong></td>
            <td><strong>Refinance home</strong></td>
            <td><strong>Refinance land & build</strong></td>
        </tr>
        `
    // Add each category products
    var standardHomeStartLoan = false;
    var graduateLoan = false;
    var lowDepositLoan = false;
    var sharedEquityLoan = false;
    var SeniorsEquity = false;
    var homeEqutiyLoan = false;
    $.each(data.categoryProducts, function (k, categoryProduct) {
        var description = categoryProduct.productDescription;
        switch (description.toString().toLowerCase()) {

            case "standard homestart loan":
                standardHomeStartLoan = categoryProduct;
                break;
            case "graduate loan":
                graduateLoan = categoryProduct;
            break;
            case "low deposit loan":
                lowDepositLoan = categoryProduct;
                break;
            case "seniors equity loan":
                SeniorsEquity = categoryProduct;
                break;
            case "shared equity loan":
            case "shared equity option":
                sharedEquityLoan = categoryProduct;
            break;
            case "home equity loan":
                homeEqutiyLoan = categoryProduct;
                break;
            default:
                console.warn("UNMATCHED PRODUCT", categoryProduct);
        }
    });
    template += rowTemplate(standardHomeStartLoan);
    template += rowTemplate(graduateLoan);
    template += rowTemplate(lowDepositLoan);
    template += rowTemplate(SeniorsEquity);
    template += rowTemplate(sharedEquityLoan);
    template += rowTemplate(homeEqutiyLoan);

    template += ` </tbody> </table> </div>`
    return template.replace('undefined','');
}

function rowTemplate(row) {
    console.log("Row:", row);
    if (!row) {
        return;
    }
    if (row.lvrCategories === undefined) {
        return;
    }
    if (row.lvrCategories.length == 0) {
        return;
    }

    var HousePurchase = false;
    var LandPurchase = false;
    var LandPurchaseBuild = false;
    var RefinanceHome = false;
    var RefinanceLandBuild = false;
    $.each(row.lvrCategories, function (k, lvrCategory) {
        console.log("LVR Category", lvrCategory);
        switch (lvrCategory.purposeDescription) {
            case "Buy Established":
            case "Buy Home":
                HousePurchase = lvrCategory;
                break;
            case "Buy Land":
            case "Land Only":
                LandPurchase = lvrCategory;
                break;
            case "Buy Land And Build":
            case "Build":
                LandPurchaseBuild = lvrCategory;
                break;
            case "Refinance A Home":
            case "Refinance":
                RefinanceHome = lvrCategory;
                break;
            case "Refinance Land And Build":
            case "Refinance land and build":
                RefinanceLandBuild = lvrCategory;
                break;
            default:
                console.warn('UNMATCHED:' + lvrCategory.purposeDescription);
                break;
        }
    });
    console.log(HousePurchase, LandPurchase, LandPurchaseBuild, RefinanceHome, RefinanceLandBuild);
    var template = `
    <tr class="product-row" >
        <td class="product-name">${row.productDescription}</td>    
            <td>${(HousePurchase !== false && HousePurchase.hasOwnProperty('lvrRate')) ? HousePurchase.lvrRate * 100 + "%" : "Not Available"}</td>
            <td>${(LandPurchase !== false && LandPurchase.hasOwnProperty('lvrRate')) ? LandPurchase.lvrRate * 100 + "%" : "Not Available"}</td>
            <td>${(LandPurchaseBuild !== false && LandPurchaseBuild.hasOwnProperty('lvrRate')) ? LandPurchaseBuild.lvrRate * 100 + "%" : "Not Available"}</td>
            <td>${(RefinanceHome !== false && RefinanceHome.hasOwnProperty('lvrRate')) ? RefinanceHome.lvrRate * 100 + "%" : "Not Available"}</td>
            <td>${(RefinanceLandBuild !== false && RefinanceLandBuild.hasOwnProperty('lvrRate')) ? RefinanceLandBuild.lvrRate * 100 + "%" : "Not Available"}</td>
    </tr>
    `
    return template;

}
